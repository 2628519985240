import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import { Home } from './components/home/Home';
// import { WTS } from './components/wts/WTS';
import Register from './components/register/Register';
import TS from './components/ts/TS';
import Panel from './components/panel/Panel';
import Payment from './components/payment/Payment';
import Analytics from './components/analytics/Analytics';
import News from './components/news/News';
import Admin from './components/admin/Admin';
// import Ins from './components/instructions/Ins';
// import Imp from './components/admin/Imp';
import Blog from './components/blog/Blog';
import Classroom from './components/classroom/classroom';
import CoursePage from './components/classroom/CoursePage';
import LiveRoom from './components/classroom/LiveRoom';
import CreateCourse from './components/classroom/CreateCourse';
import MobileHandler from './components/home/MobileHandler';
import { AuthProvider } from './Authentication/AuthContext';
import ProtectedRoute from './Authentication/ProtectedRoute';
import Error from './components/Error';
import Login from './components/Login/Login';
import Signup from './components/Login/Signup';
import Streaming from './components/classroom/LiveKit/Streaming';
import ManualEnroll from './components/classroom/ManualEnroll';
function App() {
  return (
    <Router>
      <div className="App">
      <AuthProvider>
        <Routes>
          {/* Public Routes */}
          <Route exact path="/" element={<Home/>} />
          <Route path='/login' element={<Login />} />
          <Route path="/signup" element={<Signup  />} />
          <Route path="/mobile" element={<MobileHandler/>} />
          <Route path="/ts" element={<TS/>} />
          {/* Protected Routes */}
          <Route element={<ProtectedRoute />}>  
            <Route path="/panel" element={<Panel/>} />
            <Route path="/payment" element={<Payment/>} />
            <Route path="/news" element={<News/>} />
            <Route path="/admin" element={<Admin/>} />
            <Route path="/analytics" element={<Analytics/>} />
            <Route path="/blog" element={<Blog/>} />
            <Route path="/classroom" element={<Classroom/>} />
            <Route path="/classpage/:code" element={<CoursePage/>} />
            <Route path='/classpage/:code/live-meet' element={<LiveRoom />} />
            <Route path='/classpage/:code/livestream' element={<Streaming />} />
            <Route path= "/classroom/createcourse" element={<CreateCourse/>}/>
            <Route path= "/manualenroll" element={<ManualEnroll/>}/>
          </Route>
          <Route path="/error" element={<Error/>} />
          <Route path="*" element={<Error/>} />
        </Routes>
      </AuthProvider>  
      </div>
    </Router>
  );
}

export default App;
