import React, { useState } from 'react';
import { Modal, Button, Typography, Box } from '@mui/joy';

function RefundPolicyModal() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <a onClick={handleOpen} className="f_bt_ele" style={{ cursor: 'pointer' }}>
        CANCELLATIONS & REFUND POLICY
      </a>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'white',
            padding: { xs: '16px', sm: '24px', md: '32px' },
            borderRadius: '12px',
            width: { xs: '90%', sm: '80%', md: '60%', lg: '50%' },
            maxWidth: '800px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            overflow: 'auto',
            maxHeight: '90vh',
          }}
        >
          <Typography
            level="h2"
            component="h1"
            sx={{ marginBottom: '20px', fontSize: { xs: '1.5rem', md: '2rem' } }}
          >
            Cancellation & Refund Policy
          </Typography>

          <Typography level="h6" component="h2" sx={{ fontSize: { xs: '1rem', md: '1.25rem' } }}>
            Effective Date: 10/01/2024
          </Typography>

          <Typography sx={{ marginTop: '20px', fontSize: { xs: '0.875rem', md: '1rem' } }}>
            This Cancellation & Refund Policy explains the circumstances under which Shiv Radhya IAS Academy accepts cancellation and refund requests. Our goal is to ensure that users have a clear understanding of their options and rights regarding course purchases.
          </Typography>

          <Typography level="h5" sx={{ marginTop: '30px', fontSize: { xs: '1.125rem', md: '1.5rem' } }}>
            1. No Cancellation or Refund Policy
          </Typography>
          <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>
            Once a user has successfully enrolled in a course and completed the payment process, cancellations and refunds are generally not permitted. All sales are final, and no refunds will be processed for any reason, including but not limited to:
            <ul>
              <li>Change of mind after enrollment.</li>
              <li>Inability to participate or attend courses after enrollment.</li>
              <li>Accidental purchases or mistaken payments.</li>
            </ul>
          </Typography>

          <Typography level="h5" sx={{ marginTop: '30px', fontSize: { xs: '1.125rem', md: '1.5rem' } }}>
            2. Special Circumstances
          </Typography>
          <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>
            We understand that exceptional circumstances may occur, which may warrant special consideration for cancellations or refunds. These include, but are not limited to:
            <ul>
              <li><strong>Payment Issues:</strong> You have completed a payment but did not receive enrollment confirmation or access to the course.</li>
              <li><strong>Enrollment Failure:</strong> Due to a technical error or system malfunction, your enrollment was not completed successfully after the payment was made.</li>
            </ul>
            In these situations, we may review the case and determine if a refund or alternative resolution is appropriate. To request assistance in such special cases, please contact us at:
            <br />
            <strong>Email:</strong> infosriasacademy@gmail.com
            <br />
            <strong>Phone:</strong> 7267946048
          </Typography>

          <Typography level="h5" sx={{ marginTop: '30px', fontSize: { xs: '1.125rem', md: '1.5rem' } }}>
            3. Refund Process for Special Circumstances
          </Typography>
          <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>
            If your refund request is accepted due to a special circumstance, the following process will be followed:
            <ul>
              <li>Contact us through the provided email or phone to report the issue.</li>
              <li>Our team will review your request and may ask for additional details or documentation to verify the issue.</li>
              <li>If approved, we will process the refund through the original payment method within 7-10 business days.</li>
              <li>Refunds are not guaranteed and will be at the sole discretion of Shiv Radhya IAS Academy.</li>
            </ul>
          </Typography>

          <Typography level="h5" sx={{ marginTop: '30px', fontSize: { xs: '1.125rem', md: '1.5rem' } }}>
            4. Changes to the Policy
          </Typography>
          <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>
            We reserve the right to update or modify this Cancellation & Refund Policy at any time without prior notice. Any changes will be reflected on this page with an updated "Effective Date."
          </Typography>

          <Button
            onClick={handleClose}
            sx={{ marginTop: '20px', width: '100%', fontSize: { xs: '0.875rem', md: '1rem' } }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </>
  );
}

export default RefundPolicyModal;
