import React, { useState } from 'react';
import { TextField, Button, Typography, CircularProgress, Alert } from '@mui/material';
import { Box } from '@mui/system';
import apiClient from '../../config/AxiosConfig';

const ManualEnroll = () => {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleEnroll = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await apiClient.post('/manualEnrollCourse', { email, code });
      setSuccess(response.data.message);
    } catch (error) {
      setError(error.response?.data?.message || 'Enrollment failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ maxWidth: '400px', margin: 'auto', padding: '20px', textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom>
        Manual Student Enrollment
      </Typography>
      <form onSubmit={handleEnroll}>
        <TextField
          label="Student Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          required
          margin="normal"
        />
        <TextField
          label="Classroom Code"
          type="text"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          fullWidth
          required
          margin="normal"
        />
        <Box sx={{ marginTop: 2 }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Enroll'}
          </Button>
        </Box>
      </form>

      {error && <Alert severity="error" sx={{ marginTop: 2 }}>{error}</Alert>}
      {success && <Alert severity="success" sx={{ marginTop: 2 }}>{success}</Alert>}
    </Box>
  );
};

export default ManualEnroll;
