import React, { useState } from 'react';
import { Modal, Button, Typography, Box } from '@mui/joy';

function ShippingPolicyModal() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <a onClick={handleOpen} className="f_bt_ele" style={{ cursor: 'pointer' }}>
        SHIPPING POLICY
      </a>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'white',
            padding: { xs: '16px', sm: '24px', md: '32px' },
            borderRadius: '12px',
            width: { xs: '90%', sm: '80%', md: '60%', lg: '50%' },
            maxWidth: '800px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            overflow: 'auto',
            maxHeight: '90vh',
          }}
        >
          <Typography
            level="h2"
            component="h1"
            sx={{ marginBottom: '20px', fontSize: { xs: '1.5rem', md: '2rem' } }}
          >
            Shipping Policy
          </Typography>

          <Typography level="h6" component="h2" sx={{ fontSize: { xs: '1rem', md: '1.25rem' } }}>
            Effective Date: 10/01/2024
          </Typography>

          <Typography sx={{ marginTop: '20px', fontSize: { xs: '0.875rem', md: '1rem' } }}>
            Shiv Radhya IAS Academy is an online learning platform. We provide digital content such as courses, study guides, and other learning materials that are accessible through our website.
          </Typography>

          <Typography level="h5" sx={{ marginTop: '30px', fontSize: { xs: '1.125rem', md: '1.5rem' } }}>
            1. Digital Delivery
          </Typography>
          <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>
            Upon successful enrollment and payment, course materials are delivered digitally. You will gain immediate access to the materials via your online account on our website.
          </Typography>

          <Typography level="h5" sx={{ marginTop: '30px', fontSize: { xs: '1.125rem', md: '1.5rem' } }}>
            2. Access Issues
          </Typography>
          <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>
            If you experience any delays or issues accessing your digital content, please contact us at:
            <br />
            <strong>Email:</strong> infosriasacademy@gmail.com
            <br />
            <strong>Phone:</strong> 7267946048
          </Typography>

          <Typography level="h5" sx={{ marginTop: '30px', fontSize: { xs: '1.125rem', md: '1.5rem' } }}>
            3. Refund Policy
          </Typography>
          <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>
            For details on refunds and cancellations, please refer to our cancellations and Refund Policy.
          </Typography>

          <Button
            onClick={handleClose}
            sx={{ marginTop: '20px', width: '100%', fontSize: { xs: '0.875rem', md: '1rem' } }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </>
  );
}

export default ShippingPolicyModal;
