import React, { useState } from 'react';
import { Modal, Button, Typography, Box } from '@mui/joy';

function PrivacyPolicyModal() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <a onClick={handleOpen} className="f_bt_ele" style={{ cursor: 'pointer' }}>
        PRIVACY POLICY
      </a>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'white',
            padding: { xs: '16px', sm: '24px', md: '32px' },
            borderRadius: '12px',
            width: { xs: '90%', sm: '80%', md: '60%', lg: '50%' },
            maxWidth: '800px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            overflow: 'auto',
            maxHeight: '90vh',
          }}
        >
          <Typography
            level="h2"
            component="h1"
            sx={{ marginBottom: '20px', fontSize: { xs: '1.5rem', md: '2rem' } }}
          >
            Privacy Policy
          </Typography>

          <Typography level="h6" component="h2" sx={{ fontSize: { xs: '1rem', md: '1.25rem' } }}>
            Effective Date: 10/01/2024
          </Typography>

          <Typography sx={{ marginTop: '20px', fontSize: { xs: '0.875rem', md: '1rem' } }}>
            Shiv Radhya IAS Academy ("we," "our," or "us") is committed to protecting the privacy of our users ("you" or "your"). This Privacy Policy explains how we collect, use, and share your personal information when you visit or use our website, services, and other offerings (collectively, the "Services").
          </Typography>

          <Typography level="h5" sx={{ marginTop: '30px', fontSize: { xs: '1.125rem', md: '1.5rem' } }}>
            1. Information We Collect
          </Typography>
          <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>
            We collect the following types of information from you:
            <ul>
              <li>
                <strong>Personal Information:</strong> This may include your name, email address, phone number, mailing address, or other information you provide when registering for a course, subscribing to newsletters, or contacting us.
              </li>
              <li>
                <strong>Usage Data:</strong> We collect information on how you interact with our website, such as IP address, browser type, operating system, pages visited, and time spent on our site.
              </li>
            </ul>
          </Typography>

          <Typography level="h5" sx={{ marginTop: '30px', fontSize: { xs: '1.125rem', md: '1.5rem' } }}>
            2. How We Use Your Information
          </Typography>
          <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>
            We use your information to:
            <ul>
              <li>Provide and improve our services.</li>
              <li>Respond to your inquiries and support requests.</li>
              <li>Send you information related to our courses, promotions, and updates (you can unsubscribe at any time).</li>
              <li>Analyze site performance and usage trends to enhance user experience.</li>
            </ul>
          </Typography>

          <Typography level="h5" sx={{ marginTop: '30px', fontSize: { xs: '1.125rem', md: '1.5rem' } }}>
            3. Sharing Your Information
          </Typography>
          <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>
            We do not sell or share your personal information with third parties except in the following situations:
            <ul>
              <li>
                <strong>With Service Providers:</strong> We may share your information with third-party vendors who assist us in operating our website and services.
              </li>
              <li>
                <strong>For Legal Reasons:</strong> We may disclose your information if required by law or to protect the rights, safety, or property of our users or the public.
              </li>
            </ul>
          </Typography>

          <Typography level="h5" sx={{ marginTop: '30px', fontSize: { xs: '1.125rem', md: '1.5rem' } }}>
            4. Data Security
          </Typography>
          <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>
            We implement security measures to protect your personal information. However, no method of transmission over the Internet or electronic storage is completely secure. We cannot guarantee absolute security.
          </Typography>

          <Typography level="h5" sx={{ marginTop: '30px', fontSize: { xs: '1.125rem', md: '1.5rem' } }}>
            5. Your Rights
          </Typography>
          <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>
            You have the right to:
            <ul>
              <li>Access, correct, or delete your personal information.</li>
              <li>Opt out of receiving marketing communications.</li>
              <li>Withdraw your consent for data collection where applicable.</li>
            </ul>
            To exercise your rights, please contact us at [insert contact email].
          </Typography>

          <Typography level="h5" sx={{ marginTop: '30px', fontSize: { xs: '1.125rem', md: '1.5rem' } }}>
            6. Third-Party Links
          </Typography>
          <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>
            Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of those sites. We encourage you to read their privacy policies.
          </Typography>

          <Typography level="h5" sx={{ marginTop: '30px', fontSize: { xs: '1.125rem', md: '1.5rem' } }}>
            7. Changes to the Privacy Policy
          </Typography>
          <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>
            We reserve the right to update this Privacy Policy at any time. Changes will be posted on this page with an updated "Effective Date."
          </Typography>

          <Button onClick={handleClose} sx={{ marginTop: '20px', width: '100%', fontSize: { xs: '0.875rem', md: '1rem' } }}>
            Close
          </Button>
        </Box>
      </Modal>
    </>
  );
}

export default PrivacyPolicyModal;
