import { useEffect, useRef } from 'react';
import Axios from 'axios';
import baseURL from '../../config/baseURl';

const serverBaseUrl = `${baseURL}`;

const loadScript = (src) =>
  new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => resolve(true);
    script.onerror = () => resolve(false);
    document.body.appendChild(script);
  });

const RenderRazorpay = ({
  orderId,
  keyId,
  currency,
  amount,
  email,
  exam
}) => {
  const paymentId = useRef(null);
  const paymentMethod = useRef(null);

  const displayRazorpay = async (options) => {
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

    if (!res) {
      console.log('Failed to load Razorpay SDK');
      return;
    }

    const rzp1 = new window.Razorpay(options);

    rzp1.on('payment.submit', (response) => {
      paymentMethod.current = response.method;
    });

    rzp1.on('payment.failed', (response) => {
      paymentId.current = response.error.metadata.payment_id;
    });

    rzp1.open();
  };

  const handlePayment = async (status, orderDetails = {}) => {
    try {
      await Axios.post(`${serverBaseUrl}/paymentCapture`, {
        email,
        exam,
        details: JSON.stringify(orderDetails),
        status,
      });
      window.location.reload();
    } catch (error) {
      console.error('Payment capture failed:', error);
    }
  };

  const options = {
    key: keyId, // Public key only
    amount,
    currency,
    name: 'SHIV RADHYA IAS',
    order_id: orderId, // Order ID from backend
    handler: (response) => {
      paymentId.current = response.razorpay_payment_id;

      // Send payment details to the backend for verification
      Axios.post(`${serverBaseUrl}/verifyPaymentExams`, {
        orderId,
        paymentId: response.razorpay_payment_id,
        signature: response.razorpay_signature,
      })
        .then(({ data }) => {
          if (data.success) {
            handlePayment('succeeded', {
              orderId,
              paymentId: response.razorpay_payment_id,
            });
          } else {
            handlePayment('failed', { orderId });
          }
        })
        .catch((error) => {
          console.error('Payment verification failed:', error);
          handlePayment('failed', { orderId });
        });
    },
    modal: {
      confirm_close: true,
      ondismiss: (reason) => {
        if (!reason) {
          console.log('Cancelled');
          handlePayment('Cancelled');
        } else if (reason === 'timeout') {
          console.log('Timed out');
          handlePayment('timedout');
        } else {
          console.log('Failed');
          handlePayment('failed', reason.error);
        }
      },
    },
    retry: { enabled: false },
    timeout: 900,
    theme: { color: '' },
  };

  useEffect(() => {
    displayRazorpay(options);
  }, []);

  return null;
};

export default RenderRazorpay;
