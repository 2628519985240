import React, { useState } from 'react';
import { Modal, Button, Typography, Box } from '@mui/joy';
import { styled } from '@mui/system';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  padding: theme.spacing(3),
  borderRadius: '12px',
  width: '90%',
  maxWidth: '600px',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  textAlign: 'center',
}));

const ContactInfo = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  fontSize: '1rem',
  lineHeight: '1.5',
}));

const BoldText = styled(Typography)({
  fontWeight: 'bold',
});

function ContactModal() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <a onClick={handleOpen} className="f_bt_ele" style={{ cursor: 'pointer' }}>
        CONTACT
      </a>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="contact-modal-title"
        aria-describedby="contact-modal-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <StyledBox>
          <Typography
            id="contact-modal-title"
            level="h2"
            component="h1"
            sx={{ marginBottom: '20px', fontSize: { xs: '1.5rem', md: '2rem' }, fontWeight: 'bold' }}
          >
            Contact Us
          </Typography>

          <ContactInfo>
            <BoldText>Shiv Radhya IAS Academy</BoldText>
          </ContactInfo>

          <ContactInfo>
            <BoldText>Phone:</BoldText> <a href="tel:7267946048">7267946048</a>
          </ContactInfo>

          <ContactInfo>
            <BoldText>Email:</BoldText> <a href="mailto:infosriasacademy@gmail.com">infosriasacademy@gmail.com</a>
          </ContactInfo>

          <ContactInfo>
            <BoldText>Address:</BoldText> At Jafarpur Near Pawar House, Jafarpur, Azamgarh - 276001
          </ContactInfo>

          <Button
            onClick={handleClose}
            sx={{ width: '100%', fontWeight: 'bold', fontSize: '1rem', marginTop: '20px' }}
          >
            Close
          </Button>
        </StyledBox>
      </Modal>
    </>
  );
}

export default ContactModal;
