import styled from 'styled-components';
// import sri from './images/sri.png';
import sri from './images/shi_logo.jpg';
const ResponsiveImage = styled.img`
  width: 125px;
  margin-top: 13px;
  margin-right: 80px;

  @media (max-width: 1200px) {
    width: 125px;
    margin-right: 60px;
  }

  @media (max-width: 992px) {
    width: 110px;
    margin-right: 40px;
  }

  @media (max-width: 768px) {
    width: 90px;
    margin-right: 20px;
  }

  @media (max-width: 576px) {
    width: 70px;
    margin-right: 10px;
  }
`;

const MyComponent = () => {
  return <ResponsiveImage className="
  " src={sri} alt="logo" />;
};

export default MyComponent;
