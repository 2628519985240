import React, { useState } from 'react';
import { Modal, Button, Typography, Box } from '@mui/joy';

function TermsModal() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <a onClick={handleOpen} className="f_bt_ele" style={{ cursor: 'pointer' }}>
        TERMS & CONDITIONS
      </a>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'white',
            padding: { xs: '16px', sm: '24px', md: '32px' }, // Responsive padding
            borderRadius: '12px',
            width: { xs: '90%', sm: '80%', md: '60%', lg: '50%' }, // Adjusts width based on screen size
            maxWidth: '800px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Adds subtle shadow for better appearance
            overflow: 'auto', // Enable overflow for scrolling
            maxHeight: '90vh', // Set max height to fit within viewport
          }}
        >
          <Typography
            level="h2"
            component="h1"
            sx={{ marginBottom: '20px', fontSize: { xs: '1.5rem', md: '2rem' } }}
          >
            Terms & Conditions
          </Typography>

          <Typography level="h6" component="h2" sx={{ fontSize: { xs: '1rem', md: '1.25rem' } }}>
            Effective Date: 10/01/2024
          </Typography>

          <Typography sx={{ marginTop: '20px', fontSize: { xs: '0.875rem', md: '1rem' } }}>
            These Terms & Conditions ("Terms") govern your use of the Shiv Radhya IAS Academy website and services. By accessing or using our services, you agree to these Terms.
          </Typography>

          <Typography level="h5" sx={{ marginTop: '30px', fontSize: { xs: '1.125rem', md: '1.5rem' } }}>
            1. Use of the Website
          </Typography>
          <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>
            You agree to use our website only for lawful purposes. You are prohibited from:
            <ul>
              <li>Engaging in any activity that violates applicable laws.</li>
              <li>Interfering with or disrupting our website or servers.</li>
              <li>Attempting to gain unauthorized access to our systems.</li>
            </ul>
          </Typography>

          <Typography level="h5" sx={{ marginTop: '30px', fontSize: { xs: '1.125rem', md: '1.5rem' } }}>
            2. Enrollment and Payments
          </Typography>
          <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>
            <strong>Course Enrollment:</strong> By enrolling in any course, you agree to provide accurate and complete information.
            <br />
            <strong>Payments:</strong> Payments for courses must be made in full before accessing course materials unless otherwise stated. We accept payment methods as indicated on our website.
            <br />
            <strong>Refunds:</strong> Refunds are subject to our refund policy, which may vary depending on the course and payment method. Please refer to the course-specific refund policy for details.
          </Typography>

          <Typography level="h5" sx={{ marginTop: '30px', fontSize: { xs: '1.125rem', md: '1.5rem' } }}>
            3. Intellectual Property
          </Typography>
          <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>
            All content on the website, including text, images, logos, and course materials, is owned by Shiv Radhya IAS Academy and is protected by intellectual property laws. You may not copy, distribute, or reproduce any content without our express permission.
          </Typography>

          <Typography level="h5" sx={{ marginTop: '30px', fontSize: { xs: '1.125rem', md: '1.5rem' } }}>
            4. Disclaimer
          </Typography>
          <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>
            Our website and services are provided on an "as is" basis. We make no warranties, expressed or implied, regarding the accuracy or completeness of the content on the website. We are not liable for any errors or omissions, or any damages resulting from the use of our website or services.
          </Typography>

          <Typography level="h5" sx={{ marginTop: '30px', fontSize: { xs: '1.125rem', md: '1.5rem' } }}>
            5. Limitation of Liability
          </Typography>
          <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>
            In no event shall Shiv Radhya IAS Academy be liable for any direct, indirect, incidental, or consequential damages arising from your use of our website or services, even if advised of the possibility of such damages.
          </Typography>

          <Typography level="h5" sx={{ marginTop: '30px', fontSize: { xs: '1.125rem', md: '1.5rem' } }}>
            6. Governing Law
          </Typography>
          <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>
            These Terms are governed by the laws of [Insert Jurisdiction], and any disputes arising from these Terms will be subject to the exclusive jurisdiction of the courts in [Insert Location].
          </Typography>

          <Typography level="h5" sx={{ marginTop: '30px', fontSize: { xs: '1.125rem', md: '1.5rem' } }}>
            7. Changes to Terms
          </Typography>
          <Typography sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>
            We reserve the right to update these Terms at any time. Changes will be posted on this page with an updated "Effective Date."
          </Typography>

          <Button
            onClick={handleClose}
            sx={{ marginTop: '20px', width: '100%', fontSize: { xs: '0.875rem', md: '1rem' } }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </>
  );
}

export default TermsModal;
