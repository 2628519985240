import React, { useState, useEffect } from "react";
import sri from '../home/images/sri.png';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import handleGoogleSignIn from "../register/handleSigninGoogle";
import { useAuth } from "../../Authentication/AuthContext";
import { signInWithEmailAndPassword,sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../config/firebaseConfig";

export default function Login() {
  const navigate = useNavigate();
  const {signOut} = useAuth();
  const [formData, setFormData] = useState({
    email: "",
    password: ""
  });
  
  const [errors, setErrors] = useState({
    email: "",
    password: ""
  });
  const [errorMessageF, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const validate = () => {
    let valid = true;
    let errors = {};

    if (!formData.email) {
      errors.email = "Email address is required";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email address is invalid";
      valid = false;
    }

    if (!formData.password) {
      errors.password = "Password is required";
      valid = false;
    } else if (formData.password.length < 8) {
      errors.password = "Password must be at least 8 characters";
      valid = false;
    } else if (formData.password.length > 50) {
        errors.password = "Password is too long";
        valid = false;
    } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,50}$/.test(formData.password)) {
        errors.password = "Password must contain at least one uppercase letter, one lowercase letter, one number and one special character";
        valid = false;
    } else if (formData.password.includes(" ")) {
        errors.password = "Password must not contain spaces";
        valid = false;
    }

    setErrors(errors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setIsSubmitting(true);
      signInWithEmailAndPassword(auth, formData.email, formData.password)
        .then((userCredential) => {
          console.log("User signed in successfully");
          //check if email is verified
          if (!userCredential.user.emailVerified) {
            console.log("Email not verified");
            setIsSubmitting(false);
            return;
          }
          navigate("/panel", { replace: true });
        })
        .catch((error) => {
          console.error("Sign in failed", error);
          handleFirebaseError(error);
          setIsSubmitting(false);
          alert(errorMessageF);
        });
    }
  
  };
  const handleResetPassword = () => {
    if(!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
      let errors = {};
      errors.email = "Please enter a valid email address";
      setErrors(errors);
      return;
    }
    setErrors({});
    sendPasswordResetEmail(auth, formData.email)
      .then(() => {
        alert("Password reset email sent. Please check your inbox.");
      })
      .catch((error) => {
        console.error("Password reset email failed", error);
        handleFirebaseError(error);
        alert("Password reset email failed. Please try again.");
      });
  };

  const handleFirebaseError = (error) => {
    let errorCode = error.code;
    let errorMessage = error.message;

    switch (errorCode) {
      case "auth/invalid-email":
        setErrorMessage("Invalid email address.");
        break;
      case "auth/user-disabled":
        setErrorMessage("User account is disabled.");
        break;
      case "auth/user-not-found":
        setErrorMessage("User not found. Please check your credentials.");
        break;
      case "auth/wrong-password":
        setErrorMessage("Invalid password. Please try again.");
        break;
      case "auth/popup-closed-by-user":
        setErrorMessage("Google sign-in popup closed.");
        break;
      case "auth/cancelled-popup-request":
        setErrorMessage("Google sign-in request was cancelled.");
        break;
      case "auth/popup-blocked":
        setErrorMessage("Popup blocked. Please enable popups.");
        break;
      case "auth/network-request-failed":
        setErrorMessage("Network error. Please try again later.");
        break;
      case "auth/invalid-credential":
        setErrorMessage("Invalid credentials. Please try again.");
        break;
      default:
        setErrorMessage("An error occurred. Please try again.");
        break;
    }
    
  };
    

  const handleGoogle = async () => {
    setIsSubmitting(true);
    try {
      await handleGoogleSignIn();
      console.log("Google sign in successful");
      navigate("/panel", { replace: true });
    } catch (error) {
      console.error("Google sign in failed", error);
      alert("Google sign in failed. Please try again.");
      setIsSubmitting(false);
    }
  }
  //sign out user if already signed in
  useEffect(() => {
    signOut();
  }, []);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">    
      <div className="max-w-md w-full bg-white shadow-md rounded-lg p-8 sm:p-10">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-10 w-auto"
            src={sri}
            alt="Shiv Radhya IAS"
          />
          <h2 className="mt-0 mb-2 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <form className="space-y-6" onSubmit={handleSubmit} noValidate>
          <div>
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-700 text-left">
              Email address
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
                  <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z"/>
                  <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z"/>
                </svg>
              </div>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                placeholder="xyz@gmail.com"
                required
                value={formData.email}
                onChange={handleChange}
                className={`block w-full appearance-none rounded-md border px-8 py-2 ps-10 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm font-medium ${errors.email ? "border-red-500" : "border-gray-300"}`}
              />
            </div>
            {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
          </div>

          <div>
            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-700 text-left">
              Password
            </label>
            <div className="mt-1">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="password"
                required
                value={formData.password}
                onChange={handleChange}
                className={`block w-full appearance-none rounded-md border px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm font-medium ${errors.password ? "border-red-500" : "border-gray-300"}`}
              />
            </div>
            {errors.password && <p className="text-red-500 text-xs mt-1">{errors.password}</p>}
          </div>

          <div className="flex items-end justify-end">
            <div className="text-sm">
              <div onClick={handleResetPassword} className="font-medium text-indigo-700 hover:text-indigo-500 hover:underline">
                Forgot password?
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Signing in..." : "Sign In"}
          </button>
        </form>

        <div className="mt-6 relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-600" />
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 bg-white text-gray-1000 font-semibold">Or continue with</span>
          </div>
        </div>

        <div className="mt-6 grid grid-cols-1 gap-3">
          <div className="w-full inline-flex justify-center">
            <button
              type="button"
              className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-indigo-50 hover:text-gray-900 hover:border-indigo-600 disabled:opacity-50"
              onClick={handleGoogle}
              disabled={isSubmitting}
            >
              <svg className="w-5 h-5" aria-hidden="true" style={{ display: 'block' }} viewBox="0 0 48 48">
                <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                <path fill="none" d="M0 0h48v48H0z"></path>
              </svg>
              <span className="ml-2">{isSubmitting ? "Signing in..." : "Sign In with Google"}</span>
            </button>
          </div>
        </div>

        <p className="mt-6 text-center text-sm font-medium text-gray-700">
          Don’t have an account yet?{' '}
          <Link to="/signup" className="font-medium text-indigo-700 hover:underline hover:text-indigo-500">
            Sign Up
          </Link>
        </p>
      </div>
    </div>
  );
}
